import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import Heading, { HeadingScope, HeadingVariant } from '../Heading';
import { device } from 'src/theme/breakpoints';

const Container = styled.div<CardProps>`
  background-color: ${props => (props.isDark ? colors.blue800 : colors.white)};
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  color: ${props => (props.isDark ? colors.grey500 : colors.blue700)};
`;

const Header = styled.div<CardProps>`
  border-bottom: 1px solid
    ${props => (props.isDark ? colors.blue700 : colors.grey200)};
  display: flex;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  @media ${device.laptopSUP} {
    padding: 20px 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;

  @media ${device.laptopSUP} {
    padding: 24px 60px 44px;
  }
`;

const IntroText = styled.p<CardProps>`
  font-size: 16px;
  margin: 0;
  padding: 16px 12px 0;

  @media ${device.laptopSUP} {
    font-size: 18px;
    padding: 32px 60px 0;
  }
`;

interface CardProps {
  children: React.ReactNode;
  className?: string;
  heading?: string;
  introText?: string;
  isDark?: boolean;
}

const Card: FC<CardProps> = ({
  children,
  className,
  introText,
  isDark,
  heading,
}) => (
  <Container isDark={isDark}>
    {heading && (
      <Header isDark={isDark}>
        <HeadingScope>
          <Heading variant={HeadingVariant.DELTA}>{heading}</Heading>
        </HeadingScope>
      </Header>
    )}
    {introText && <IntroText>{introText}</IntroText>}
    <Body className={className}>{children}</Body>
  </Container>
);

export default Card;
