import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import Card from 'src/components/Card';
import { colors } from 'src/theme/colors';
import { LinkAnimation } from 'src/utils/animations/animations';
import { CONTACTS } from 'src/utils/constants';
import Layout from 'src/components/Layout';
import Heading, { HeadingVariant } from 'src/components/Heading';
import { device } from 'src/theme/breakpoints';
import Content from 'src/components/Content';
import { CaretIcon } from 'src/assets/icons';
import Modal from 'src/components/Modal';
import ContactForm from 'src/components/ContactForm';

const CardsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  margin-bottom: 60px;

  @media ${device.laptopSUP} {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 120px;
  }
`;

const StepWrapper = styled.div`
  align-items: flex-start;
  display: flex;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }

  @media ${device.laptopSUP} {
    &:not(:first-child) {
      margin-top: 48px;
    }
  }
`;

const StepNumber = styled.div`
  align-items: center;
  background-color: ${colors.blue200};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: 32px;
  justify-content: center;
  margin-right: 16px;
  max-width: 32px;
  min-width: 32px;
  width: 32px;

  @media ${device.laptopSUP} {
    font-size: 30px;
    height: 64px;
    margin-right: 32px;
    max-width: 64px;
    min-width: 64px;
    width: 64px;
  }
`;

const StepHeading = styled.p`
  font-size: 22px;
  line-height: 1.2;
  margin: 0;

  @media ${device.desktop} {
    font-size: 30px;
  }
`;

const Link = styled.a`
  ${LinkAnimation}
  color: ${colors.purple400};
  text-decoration: none;

  &:after {
    background-color: ${colors.purple400};
  }
`;

const StyledHeading = styled(Heading)`
  margin: 40px 0 80px;
  text-align: center;

  @media ${device.desktop} {
    font-size: 48px;
    margin: 80px 0;
  }
`;

const Text = styled.p`
  color: ${colors.grey400};
`;

const StyledCard = styled(Card)`
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 12px;

  @media ${device.laptopSUP} {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const CTA = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.purple800};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;

  @media ${device.laptopSUP} {
    ${LinkAnimation}
    padding: 0 0 7px 0;

    &:after {
      background-color: ${colors.purple800};
    }
  }
`;

const StyledCaretIcon = styled(CaretIcon)`
  margin-left: 12px;
  transform: scale(0.7);
`;

const ContactsPage: FC = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <StyledHeading variant={HeadingVariant.ALFA}>
          <Trans i18nKey="contacts_heading_0" />
          <span className="highlight">
            <Trans i18nKey="contacts_heading_1" />
          </span>
        </StyledHeading>
        <CardsWrapper>
          <StyledCard
            heading={t('contacts_first_card_heading')}
            introText={t('contacts_first_card_intro')}
          >
            <StepWrapper>
              <StepNumber>1</StepNumber>
              <div>
                <StepHeading>
                  <Trans i18nKey="common_give_us_call" />
                </StepHeading>
                <Link href={`tel:${CONTACTS.PHONE}`}>{CONTACTS.PHONE}</Link>
              </div>
            </StepWrapper>
            <StepWrapper>
              <StepNumber>2</StepNumber>
              <div>
                <StepHeading>
                  <Trans i18nKey="common_send_us_email" />
                </StepHeading>
                <Link href={`mailto:${CONTACTS.EMAIL}`}>{CONTACTS.EMAIL}</Link>
              </div>
            </StepWrapper>
            <StepWrapper>
              <StepNumber>3</StepNumber>
              <div>
                <StepHeading>or simply fill out this form</StepHeading>
                <CTA onClick={() => setShowFormModal(true)}>
                  Fill out form
                  <StyledCaretIcon />
                </CTA>
                <Modal
                  isOpen={showFormModal}
                  contentLabel={t('common_start_now')}
                  onClose={() => setShowFormModal(false)}
                >
                  <ContactForm />
                </Modal>
              </div>
            </StepWrapper>
          </StyledCard>
          <StyledCard
            heading={t('contacts_second_card_heading')}
            introText={t('contacts_second_card_intro')}
          >
            <StepWrapper>
              <StepNumber>1</StepNumber>
              <div>
                <StepHeading>
                  <Trans i18nKey="common_send_us_email" />
                </StepHeading>
                <Link href={`mailto:${CONTACTS.SUPPORT_EMAIL}`}>
                  {CONTACTS.SUPPORT_EMAIL}
                </Link>
                <Text>
                  <Trans i18nKey="contacts_support" />
                </Text>
              </div>
            </StepWrapper>
          </StyledCard>
        </CardsWrapper>
      </Content>
    </Layout>
  );
};

export default ContactsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Contacts | Instegral" />;
